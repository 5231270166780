export default {
    common: {
        placeUsername: "수금 사용자를 입력하십시오.",
        userTransfer: "사용자 이체",
        expected: "예상 이익",
        view: "보기",
        confirm: "확정",
        cancel: "취소",
        deposit: "입금",
        withdraw: "출금",
        noData: "데이터 없음",
        submit: "제출",
        modify: "수정",
        hand: "손",
        confirm_verify: "확인 검증",
        next: "다음 단계",
        complete: "완료",
        delete: "삭제",
        countryCode: "국가 코드",
        no_balance: "계좌 잔액 부족",
    },
	currency: {
		btcusdt: "비트코인(가상 화폐)",
		ethusdt: "이더랜드",
		ltcusdt: "라이트코인(가상 화폐)",
		xrpusdt: "리플(통화)",
		adausdt: "통화(분수 통화로 사용)",
		eosusdt: "포멜로 코인",
		dotusdt: "보카 레이튼(구 보카 레이튼), 러시아 통화",
		trxusdt: "웨이브 농지 코인 (컴퓨팅)",
		xmrusdt: "먼로 동전(예: 미국 달러)",
		dogeusdt: "강아지 코인",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "호주 달러 미국 달러",
		fx_sgbpusd: "파운드-달러",
		fx_seurusd: "유로달러",
		fx_snzdusd: "뉴질랜드 달러(NZD) 미국 달러",
		fx_susdcad: "미국 달러-캐나다 달러",
		fx_susdchf: "미국 달러 스위스 프랑",
		fx_susdjpy: "미국 달러-엔",
		fx_saudcad: "호주 달러 캐나다 달러",
		fx_saudchf: "호주 달러 및 스위스 프랑",
		fx_saudjpy: "호주 달러 일본 엔",
		fx_saudnzd: "호주 달러 뉴질랜드 달러(NZD)",
		fx_scadchf: "캐나다 달러 스위스 프랑",
		fx_scadjpy: "캐나다 달러 일본 엔",
		fx_schfjpy: "스위스 프랑 엔",
		fx_seuraud: "유로 호주 달러",
		fx_seurcad: "유로 캐나다 달러",
		fx_seurgbp: "유로 파운드 스털링",
		fx_seurjpy: "유로 엔",
		fx_seurnzd: "유로 뉴질랜드 달러(NZD)",
		fx_sgbpaud: "영국 파운드 호주 달러",
		fx_sgbpchf: "파운드 스털링 스위스 프랑",
		fx_sgbpjpy: "GBPJPY",
		fx_sgbpnzd: "파운드 뉴질랜드 달러(NZD)",
		fx_scadnzd: "캐나다 달러 뉴질랜드 달러",
		hf_CL: "WTI 뉴욕 원유",
		hf_OIL: "브렌트유",
		hf_GC: "뉴욕 골드",
		hf_SI: "뉴욕 실버",
		hf_HG: "구리(외래어)",
		hf_NG: "미국 천연 가스",
		hf_CAD: "구리(외래어)",
		hf_AHD: "런던 알루미늄",
		HX_AAPL: "석류",
		HX_BABA: "아라비안나이트의 캐릭터 알리 바바",
		HX_GOOG: "구글",
		HX_WMT: "월마트, 월마트(소매업체)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "니콜라 테슬라(1856-1943), 세르비아의 발명가이자 엔지니어",
		HX_N225: "닛케이 225",
		HX_GDAXI: "DAX30, 독일",
		HX_IBEX: "IBEX35, 스페인",
		HX_SPX: "S&P 500 지수",
		HX_FTSE: "FTSE 100",
		HX_KS11: "코스피 한국",
		HX_NZ50: "뉴질랜드 50",
		HX_PSI20: "포르투갈 PSI20",
		hf_ZSD: "런던 아연",
		hf_NID: "런던 니켈",
		hf_PBD: "런던 리드",
		hf_SND: "런던 주석",
		hf_XAU: "런던 골드(현물 골드)",
		hf_XAG: "런던 실버(현물 실버)",
		hf_XPT: "플래티넘 선물",
		hf_XPD: "팔라듐 선물",
		hf_CT: "미국 코튼",
		hf_SM: "대두 분말",
		hf_BO: "미국산 대두유",
		HX_SENSEX: "센섹스, 뭄바이, 인도",
		HX_KLSE: "FTSE 말레이시아 KLCI",
		HX_KSE100: "카라치, 파키스탄",
		HX_FCHI: "CAC40, 프랑스",
		HX_SX5E: "유럽 스누커 50",
		HX_ICEXI: "ICEX, 아이슬란드",
		HX_ASE: "ASE 아테네, 그리스",
		HX_OMXC20: "OMX 코펜하겐 20",
		HX_OSEBX: "OSEBX, 노르웨이",
		HX_OMXSPI: "옴스파이, 스웨덴",
		HX_AMD: "차오웨이 반도체",
		HX_DIS: "디즈니(이름)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "마스터카드(브랜드)",
		HX_NVDA: "NVIDIA, 컴퓨터 그래픽 카드 회사",
		HX_INTC: "인텔",
		HX_GOGO: "Gogo Inc",
		HX_STX: "씨게이트 기술",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "아스트로코인",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
    currenctType: {
        title: "화폐를 선택하세요",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'ドル',
        currenctType15: '香港ドル',
        currenctType16: '台湾ドル',
        currenctType17: 'コイン枚',
        currenctType18: '新貨',
        currenctType19: '円',
        currenctType20: 'ユーロ',
        currenctType21: '豪ドル',
        currenctType22: 'インドネシア盾',
        currenctType23: 'ポンド',
        currenctType24: 'バーツ',
        currenctType25: 'かさん',
        currenctType26: 'ベトナムドン',
        currenctType27: '韓国ドル',
        currenctType28: 'マカオドル',
    },
    addnew3: {
        direction: "방향",
        sell_price: "판매율",
        number: "수량",
        type: "배달 시간",
        buy_price: "입찰 가격",
        profit: "이익/손실",
        sell_time: "판매 시간",
    },
    addnew2: {
        address: {
            account_type: "계정 유형",
            ifsc_code: "IFSC 코드",
            title: "지갑 주소",
            account_number: "계좌 번호",
            add_btn: "주소 추가",
            label_type: "유형 선택",
            place_type: "유형을 선택하세요",
            label_number: "계좌번호",
            place_number: "계좌번호를 입력하세요",
            wallet_address: '지갑 주소',
            bank_name: '은행 이름',
            bank_address: "은행 주소",
            name: "이름",
            payee_address: '수취인 주소',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "지점 이름",
            branch_code: "지점 코드",
            bank_code: "은행 코드",
            phone: "핸드폰 번호",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "내용을 입력하십시오.",
        },
    },
    addnew: {
        realFirst: "먼저 인증하십시오.",
        payFirst: "먼저 거래 비밀번호를 설정하십시오.",
        password1: "로그인 암호 수정",
        password2: "인출 비밀번호 수정",
        paypassTit1: "인출 비밀번호를 설정하십시오.",
        oldPass: "이전 암호",
        allView: "모두 읽음",
        confirmOrder: "주문 확인",
        name: "이름",
        fee: "수수료",
        orderCycle: "시간 선택",
        loan: "대출",
        loanTit: "조력 대출",
        loanHostory: "대출 기록",
        labelProductTitle: "제품 이름",
        immediateRepayment: "즉시 상환하다",
        loadAmount: "예상 대출 금액",
        RepaymentCycle: "대출 상환 주기",
        day: "일",
        dailyRate: "일일 이율",
        repaymentMethod: "상환 방식",
        lendingCompany: "대출 회사",
        loanTip: "신용 대출 (그림이 잘 보이는지 확인하십시오)",
        loanTip1: "부동산 증서를 올리다",
        loanTip2: "소득증명(고용관계)",
        loanTip3: "은행 명세서 상세 정보",
        loanTip4: "주민등록증 정면 사진 올리기",
        interest: "이익",
        repaid: "이미 상환",
        unpaid: "미상환",
        tab1: "코인",
        tab2: "계약",
        tab3: "옵션",
        available: "가용 한도",
        pending: "잠금",
        equivalent: "환산",
        fundTransfer: "자금 이전",
        flashExchange: "환전",
        select_breed: "통화 선택",
        from: "부터",
        to: "다음",
        transferQuantity: "전송 수량",
        repaymentMethod1: "만기가 되면 한 번 상환한다."
    },
    params: {
        product_foreign: "외화",
        product_shop: "상품",
        product_number: "지수",
        product_stock: "주식",
        product_coin: "암호화",
        billTypes: [
            { v: 101, name: '충전' },
            { v: 102, name: '추가' },
            { v: 103, name: '동결' },
            { v: 104, name: '해동' },
            { v: 105, name: '예치' },
            { v: 106, name: '예치' },
            { v: 201, name: '현금 인출 동결' },
            { v: 202, name: '공제' },
            { v: 203, name: '현금 인출 성공' },
            { v: 204, name: '현금 인출 실패' },
            { v: 205, name: '현금 인출 수수료' },
            { v: 206, name: '전출' },
            { v: 207, name: '전입' },
            { v: 208, name: '코인 전출' },
            { v: 209, name: '코인 입금' },
            { v: 301, name: '보증금 공제' },
            { v: 302, name: '수수료 공제' },
            { v: 303, name: '보증금 반환' },
            { v: 304, name: '이익' },
            { v: 305, name: '결손' },
            { v: 311, name: '구매 옵션' },
            { v: 312, name: '옵션 이익' },
            { v: 313, name: '옵션 반환' },
            { v: 314, name: '옵션 수수료' },
            { v: 315, name: '코인 매입 동결' },
            { v: 316, name: '코인 매입 공제' },
            { v: 317, name: '코인 매입 반환' },
            { v: 318, name: '코인 매입 입금' },
            { v: 319, name: '코인 매입 입금' },
            { v: 320, name: '코인 판매 동결' },
            { v: 321, name: '코인 판매 공제' },
            { v: 322, name: '코인 판매 반환' },
            { v: 323, name: '화폐가 장부에 팔렸다.' },
            { v: 324, name: '화폐가 장부에 팔렸다.' },
            { v: 325, name: '코인 수수료' },
            { v: 401, name: '광산 기계 가입' },
            { v: 402, name: '광산 기계 귀환' },
            { v: 403, name: '광산 기계 수입' },
            { v: 404, name: '광산 기계 종료' },
            { v: 405, name: '광산 기계 퇴출 수수료' },
            { v: 411, name: '차관 수입' },
            { v: 412, name: '대출 수수료' },
            { v: 413, name: '상환 성공' },
            {v: 421, name: '사용자 전송'},
            {v: 422, name: '사용자 이동'},
        ],
    },
    lockming: {
        profit: "최근(일일 수익)",
        cycle: "재무 주기",
        limit: "단일 한도",
        dividend_time: "배당금 지급 시간",
        every_day: "매일",
        funds: "에스크로 자금",
        back: "만기 시 수익률",
        ransom: "조기 상환",
        label_amount: "서약 금액",
        place_amount: "입력하세요,,,",
        available: "사용 가능",
        expect_income: "예상 수입",
        title: "잠긴 채굴",
        tip: "수입을 위한 채굴",
        label_today_income: "오늘 예상 수입",
        label_total_income: "누적 수입",
        label_order: "에스크로에 있는 주문",
        label_number: "단일 금액",
        daily_return: "일일 수익",
        subscribe: "구독",
        position: "포지션",
        label_state: "상태",
        subscribe_time: "구독 시간",
        expiration_time: "만료 시간",
        income: "수입",
        errorTip1: "금액을 입력하세요",
        tip_tit: "조기 탈퇴하시겠습니까?",
    },
    footer: {
        nav1: "첫 페이지",
        nav2: "거래",
        nav3: "창고 위치",
        nav4: "나의",
        kline_set1: "시세 설정",
        kline_set2: "거래 설정",
    },
    login: {
        login: "로그인",
        register: "등록",
        type1: "핸드폰 번호",
        type2: "메일박스",
        login_btn: "보안 로그인",
        register_btn: "지금 등록",
        psd_login: "비밀번호 로그인",
        verify_login: "인증 코드 로그인",
        forget: "암호를 잊었습니다.?",
        no_account: "계정 없음？",
        have_account: "기존 계정",
    },
    home: {
        home: {
            nav1: "등락 순위",
            nav2: "빠른 시작",
            nav3: "우리에 대해",
            nav4: "초대 보상",
            most_follow: "최대 관심",
            news_type1: "정책",
            news_type2: "뉴스",
            news_type3: "경제 달력",
            news_tab1: "모두",
            news_tab2: "외화",
            news_tab3: "주식",
            news_tab4: "상품",
            news_tab5: "지수",
            news_tab6: "데이터",
            news_tab7: "이벤트",
            news_tab8: "휴가",
            label_previous: "전치",
            label_predictive: "예측치",
            label_announced: "공표 값",
        },
        kilne: {
            label_bp: "어제 수확",
            label_sp: "금개",
            label_height: "최고",
            label_low: "최소",
            tip: "Hi, CoinstoreClubGPT 품목 도우미입니다.",
            row1_tit: "정책",
            row1_rinei: "일내",
            row1_short: "단기",
            row1_middle: "중기",
            row1_time: "출시 날짜",
            row1_tit2: "거래 전략",
            data_sources: "데이터 원본",
            row2_tit1: "데이터",
            row2_tit2: "거래 감정",
            row2_label_sell: "판매자",
            row2_label_buy: "구매자",
            tip2: "참고로 제공할 뿐, 본사의 입장으로 삼지 않는다.",
            tip3: "15분마다 업데이트",
            row2_tit3: "가격이 오르락내리락하다",
            minute: "분",
            today: "오늘",
            right_tit7: "가격 변화 구간",
            now_price: "현재 가격",
            right_low_price: "최저가",
            right_height_price: "최고가",
            details: "상세 정보",
            right_label1: "단일 거래 수",
            right_label2: "최대 레버",
            right_label3: "계약 크기",
            right_label4: "최대 총 보유 수량",
            right_label5: "변동 가격 차",
            right_label6: "하룻밤 요금",
            hand: "손",
            right_sell: "매도",
            right_buy: "매입",
            right_collection_time: "수취 시간",
            right_tip3: "전체 거래 규모에서 수취",
            right_label9: "거래 기간",
            right_label10: "현재 시간",
            right_label11: "다음 시간",
        },
        placeorder: {
            direction_buyPrice: "매입가",
            direction_sellPrice: "매도 가격",
            direction_sell: "매도",
            direction_buy: "매입",
            market_price: "시세표",
            check_price: "청구서",
            sell_tip: "가격이 조건을 만족시킬 때 팔다",
            buy_tip: "가격이 조건을 만족시킬 때 매입하다",
            trade_number: "거래 수량",
            hand: "손",
            select_lever: "레버 선택",
            right_tip: "보증금을 지불해야 한다",
            balance: "사용 가능한 잔액",
            label_stop_win_price: "그치다",
            label_stop_lose_price: "손실 방지",
            expected_profit_loss: "예상 손익",
            trailing_stop: "추적 손실 방지",
        },
        ranking: {
            tit: "당일 등락 순위",
            p: "핫스팟 포착 및 기회 포착",
            renew: "업데이트",
            growth_chart: "상승폭 순위",
            drop_list: "낙폭 순위",
            show_closed_market: "휴장 브랜드 전시",
            filter1: "모두",
            filter2: "외화",
            filter3: "상품",
            filter4: "주식",
            filter5: "지수",
            filter6: "암호화",
            td1: "순위",
            td2: "추세",
            td3: "당일 상승폭/매도 가격",
        },
        remind: {
            now_sell_price: "현재 판매 가격",
            direction_buyPrice: "매입가",
            direction_sellPrice: "매도 가격",
            direction_sell: "매도",
            direction_buy: "매입",
            equal: "저당",
            p: "다음 가격에 도달하면 알려 주십시오",
            btn1: "알림 설정",
            list_tit: "알림 목록",
        },
    },
    my: {
        report: {
            tit: "계정 보고서",
            tab1: "보고",
            tab2: "일 결산서",
            tab3: "월 결산서",
            history_tit: "거래 내역 보고서",
            account_tit: "계정 보고서",
            filter: "필터링",
            tip1: "거래 내역 보고서를 내보내려면 적절한 날짜 범위를 선택하십시오.",
            tip2: "사용자 지정 시간",
            email_send: "사서함으로 보내기",
        },
        accountMsg: {
            tit: "계정 정보",
            label1: "개인 정보",
        },
        bill: {
            tit: "자금 흐름 내역",
            all: "모두",
            time: "시간",
        },
        feedback: {
            tit: "기능 제안",
        },
        identity: {
            tit: "인증",
            type1: "신분증",
            type2: "운전면허증",
            type3: "여권",
            tip: "제공된 모든 정보와 파일이 완전하고 사실적이며 정확한지 확인합니다.",
        },
        my: {
            is: "이미",
            no: "검증되지 않음",
            verify: "검증됨",
            id_verify: "인증",
            verify_tip: "인증 완료 시 입금 거래 가능",
            no_verify: "지금 확인",
            balance: "사용 가능한 잔액",
            profit_loss: "손익",
            marginLevel: "보증금 수준",
            btn1: "실제 계정으로 전환",
            deposit_withdraw_record: "출입금 기록",
            capital_flow_details: "자금 흐름 내역",
            welfare_center: "복지 센터",
            tip1: "지원",
            tip2: "펜 보상 수령 대기 중",
            feedback: "기능 제안",
            report: "계정 보고서",
            setting: "설치",
        },
        news: {
            tit: "알림",
        },
        record: {
            tit: "출입금 기록",
            cycle: "주기",
        },
        share: {
            tit: "친구 초대",
            invite_success: "친구 초대 성공",
            tip1: "현금 보상",
            tip2: "친구를 초대하면 최고로 받습니다.",
            tip3: "친구를 초청하여 현금을 수령하다.",
        },
        welfare: {
            tit: "복지 센터",
            tab1: "카드 볼륨",
            tab2: "활동",
            history_record: "기록",
            period_validity: "유효기간",
        },
        wallet: {
            tit: "지갑",
            addNew: "새 지갑 추가",
        },
        withdraw: {
            tit: "출금",
            addNew: "새 지갑 추가",
        },
    },
    order: {
        demo_account: "아날로그 계정",
        real_account: "실제 계정",
        tip: "개통 후 입금 거래 가능",
        tab1: "창고 보유",
        tab2: "청구서",
        tab3: "역사",
        list_empty: "현재 등록 없음",
        start_trade: "거래 시작",
        hand_tip: "입력 가능 범위",
        kline: {
            direction_sell: "매도",
            direction_buy: "매입",
            open_price: "창고 개설 가격",
            now_price: "현재 가격",
            trade_account: "거래 계좌",
            demo_account: "아날로그 계정",
            order_id: "주문 ID",
            open_time: "창고 개설 시간",
            stop_profit: "그치다",
            stop_loss: "손실 방지",
            trailing_stop: "추적 손실 방지",
            float_profit_loss: "변동 손익",
            overnight_fee: "하룻밤 요금",
            margin: "보증금",
            maintainsMargin: "유지 보증금",
            open_value: "창고 개설 가치",
            now_value: "현재 가치",
            notset: "설정되지 않음",
            close_position: "창고 정리",
            close_number: "보통 수량",
            onclick_close: "원클릭 창고 정리",
            btn: "창고 정리 확인",
            balance: "사용 가능한 잔액",
            profit_loss: "손익",
            netValue: "순가치",
            marginLevel: "보증금 수준",
        },
        modify: {
            tit: "창고 명세서를 수정하다.",
            expected_profit_loss: "예상 손익",
        },

    },
    setting: {
        security: {
            modify_password_tit: "새 암호 설정",
            verify_email_tit: "메일박스 확인",
            verify_password_tit: "암호 확인",
            verify_password_tip: "계속하려면 CoinstoreClub 로그인 암호를 입력합니다.",
            verify_phone_tit: "휴대폰 번호 확인",
            tit: "계정 보안",
            tip: "귀하의 계정을 보호하고 저희의 서비스 통지를 받기 위해 최소한 하나의 신분 검증을 하는 것을 권장합니다.",
            label1: "암호 수정",
            label2: "계정 로그아웃",
            label3: "이중 검증",
            label4: "장치 관리",
            tip2: "새 장치 로그인 시 확인 필요",
        },
        tradenews: {
            tit: "거래 안내서",
            tab1: "빠른 시작",
            tab2: "진급 학습",
            tab3: "도움말 센터",
            tab4: "CoinstoreClub 정보",
            viewMore: "자세히 보기",
            details: {
                tit: "외환 소개",
                tip1: "예상 학습 시간",
                tip2: "분",
            },
            tab1Row: {
                tit: " 이것은 CoinstoreClub로 거래하는 것을 빠르게 배우는 수업입니다.",
                tit1: "CoinstoreClub에서는 어떻게 거래됩니까?",
                tit2: "실제 계좌를 개설하는 방법？",
                tit3: "어떻게 창고를 개설하여 교역할 것인가？",
                tit4: "어떻게 수영 정지와 손상 정지를 설정합니까？",
                tit5: "어떻게 창고를 정리합니까？",
                p1: "당신은 매입하거나 팔아서 이 목적물을 거래할 수 있습니다.CoinstoreClub에서 창고를 열면 이것은 당신이 이",
                p2: "대상 제품의 가격 변동으로 매매하다.",
                p3: "예를 들어, 금 가격이 상승할 것으로 예상하면 이 제품을 매입합니다.반대로 당신은 금 가격이 하락할 것이라고 예측하면 이 제품을 판매합니다.미래에 대비하여",
                p4: "이득을 보다",
                p5: "또한 CoinstoreClub는 '레버리지 거래'를 제공합니다.당신은 레버리지를 이용하여 같은 자금으로 더 많은 수의 목적물을 거래할 수 있습니다.",
                p6: "레버리지는 이윤을 늘릴 수도 있고, 손실을 늘릴 수도 있다",
                p7: "이를'보증금 거래'라고도 한다.창고를 개설하면 CoinstoreClub 계좌에서 일정 잔액을 공제합니다",
                p8: "초기 보증금",
                p9: "제품 및 거래 방향 선택",
                p10: "거래",
                p11: "'매입 /'' 을 클릭하여 주문 완료",
                p12: "'손익 방지'' 또는 '손손 방지'' 를 설정하면 도움이 됩니다.",
                p13: "적시에 이윤을 억제하고 손실을 제한하다",
                p14: "창고 개설 성공 페이지에서 클릭할 수 있습니다",
                p15: "손익 / 손실 방지",
                p16: "설정하거나 나중에 비평합단의'수정'을 클릭하여 손익을 정지할 수 있는",
                p17: "설정 또는 취소",
                p18: "'창고 정리'에서 손익을 볼 수 있습니다",
            },
            tab3Row: {
                h1: "어서 오세요 CoinstoreClub",
                h2: "도움말 센터",
                tit0: "모든 문제",
                tit1: "아날로그 계정",
                tit2: "계좌를 개설하다",
                tit3: "입금과 출금",
                tit4: "플랫폼 설정",
                tit5: "거래 조작",
                tit6: "비용 및 비용",
                tit7: "자금 보안 및 규정",
                tit1Tit1: "CoinstoreClub 아날로그 계정 사용 여부",
                tit1Cont1: "<p>CoinstoreClub 고객이 사용할 수 있는 아날로그 계정 제공，위험 없이 네트워크 플랫폼 및 모바일 애플리케이션을 사용하여 거래하고 플랫폼 운영을 익힐 수 있습니다.</p>",
                tit1Tit2: "아날로그 계정을 만드는 방법?",
                tit1Cont2: "<p>이메일 주소 또는 휴대폰 번호를 입력하려면 여기를 클릭하십시오.，개인 암호 설정，등록을 완료하고 아날로그 계정을 받을 수 있습니다.。</p>",
                tit1Tit3: "아날로그 계정이 무슨 소용이 있는지?",
                tit1Cont3: "<p>시뮬레이션 계좌는 실제 계좌와 거래 인터페이스, 데이터, 조작에 있어서 대부분이 똑같다. 시뮬레이션 계좌에는 5만 달러의 가상 자금이 있는데 그 목적은 고객이 자금 위험이 없는 상황에서 시뮬레이션 조작을 통해 플랫폼의 각종 기능을 익히도록 하는 것이다。</p>",
                tit1Tit4: "어떻게 모의 계좌에 자금을 투입합니까?",
                tit1Cont4: "<p>당신은 시뮬레이션 계좌에 자금을 투입할 필요가 없습니다. 시뮬레이션 계좌를 성공적으로 등록하면 계좌 안에 5만 달러의 USD 또는 호주달러 AUD의 올리브 자금이 전부 있을 것입니다.</p><p>그리고 시뮬레이션 계좌 내 순가치가 200달러 USD 또는 호주달러 AUD 미만일 경우, 시스템은 자동으로 귀하의 계좌 자금을 5만 달러 USD 또는 호주달러 AUD로 보충합니다</p>",
                tit1Tit5: "아날로그 계정의 사용 기간 여부?",
                tit1Cont5: "<p>아날로그 계정의 유효기간은 90일이며 90일 후에 실제 계정을 개설하지 않으면 아날로그 계정은 동결되고 거래할 수 없으며 실제 계정을 개설할 때 동결해제됩니다.。</p><p>90일 이내에 실제 계정을 개설하면 시뮬레이션 계정은 장기간 유효합니다.실제 계정 개설 후에도 아날로그 계정에서 계속 작동。</p>",
                tit2Tit1: "CoinstoreClub 제공되는 계정 유형?  ",
                tit2Cont1: "<p>우리는 개인에게만 표준 및 전문 거래 계좌를 제공한다，회사 계정 및 연명 계정은 지원되지 않습니다.</p>",
                tit2Tit2: "나는 어떤 화폐의 거래 계좌를 개설할 수 있습니까?",
                tit2Cont3: "<p>귀하가 계좌를 개설할 때 시스템은 귀하의 지역에 따라 귀하의 결제 화폐를 자동으로 확정하고 수정할 수 없습니다.</p>",
                tit2Tit4: "실제 계좌를 개설하는 방법?",
                tit2Cont4: "<p>실제 계좌를 개설하려면 아래 절차에 따라 조작하십시오.</p><p>1. 메시지에 따라 CoinstoreClub 등록 페이지로 이동하여 메일박스 휴대폰 번호 등록, 로그인 비밀번호 설정, 제출 확인 후 자동으로 생성</p><p></p><p></p>",
                tit3Tit1: "제가 어떤 식으로 입금을 할 수 있는지.?",
                tit3Cont1: "<p>CoinstoreClub는 다음을 포함하여 고객이 선택할 수 있는 다양한 입금 방법을 제공합니다:</p><p>1.Visa/Mastercard - 보통 즉시 입금</p><p>2.인터넷 뱅킹 지불은 일반적으로 1 일 일찍 T일 내에 한다</p><p>3.전자 지갑 (예컨대: Skrill、Momo、Zalo.Touch'n GO및Boost등) - 보통 즉시 입금</p><p>4.ATM 카드. - 보통 바로 입금이에요.</p><p>5.QR 모바일 뱅킹. - 보통 바로 입금.</p>",
                tit3Tit2: "제 3 자 명의의 은행 계좌 / 카드 입금 또는 출금을 이용할 수 있습니까?",
                tit3Cont2: "<p>CoinstoreClub제3자의 입금을 받지 않다.본인 명의의 개인 은행 계좌/카드를 사용하여 입금 또는 출금하십시오.귀하가 다른 사람의 명의나 회사 은행 계좌 / 카드 입금을 사용하면 자금은 반환됩니다.</p>",
                tit3Tit3: "CoinstoreClub 계좌에서 출금을 신청하는 방법?   ",
                tit3Cont3: "<p>귀하는 사이트 플랫폼에 로그인한 후 출금]을 클릭하고 은행 계좌나 Visa/Mastercard를 선택하여 출금 금액을 기입한 후 [제출]을 누르면 자료가 정확한지 확인한 후 CoinstoreClub는 1-2일 근무일 내에 귀하의 신청을 처리할 수 있습니다.</p>",
                tit4Tit1: "CoinstoreClub몇 배의 레버리지 거래를 제공합니까?",
                tit4Cont1: "<p>CoinstoreClub플랫폼이 제공하는 레버리지 배수는 감독관리 당국의 규정에 따라 제정한 것이며, 서로 다른 제품의 최고 레버리지도 서로 다른 상세한 정보가 있으니 거래 플랫폼에 접속하여 확인하시기 바랍니다。</p>",
                tit4Tit2: "내 암호를 변경하는 방법?",
                tit4Cont2: "<p>플랫폼에 로그인한 후 [추가] - [보안 설정] - T 암호 수정] 을 선택하여 새 암호를 재설정할 수 있습니다.</p>",
                tit4Tit3: "어떻게 CoinstoreClub 거래 플랫폼에 로그인합니까?",
                tit4Cont3: "<p>App:'내 것'으로 이동하여 왼쪽 위'로그인 l 등록'을 클릭하여 로그인 페이지로 이동。</p>Web: CoinstoreClub 공식 웹 사이트 오른쪽 상단의'로그인'을 클릭하여 로그인 페이지로 이동。<p></p><p></p><p></p><p></p>",
                tit4Tit4: "플랫폼 알림 방법?",
                tit4Cont4: "<p>CoinstoreClub 이메일, 문자, 플랫폼 푸시를 통한 알림。</p>",
                tit5Tit1: "CoinstoreClub 어떤 가격 차를 제공합니까?",
                tit5Cont1: "<p>우리는 낮은 가격의 차를 받고, 어떠한 커미션도 받지 않는다.가격차는 변동적이며 실제 수취하는 가격차는 귀하가 거래하는 품목에 따라 결정되며 구체적으로 거래 플랫폼의 즉각적인 표시에 따라 결정됩니다.。</p>",
                tit5Tit2: "어떻게 시가표를 세웁니까?",
                tit5Cont2: "<p>[거래] (Trade) 를 클릭하면 플랫폼에서 거래할 수 있는 모든 제품이 표시됩니다. 오른쪽 입력란에 품목 코드나 이름을 입력하여 검색할 수 있습니다. 한 제품을 선택하여 [매수/매도] SELL/BUY] 를 클릭하면 거래 창이 팝업됩니다. 거래 창에서 현재 가격과 예상 보증금을 볼 수 있습니다.당신은 수동으로 창고 개설 거래 수량을 조절하고, 이윤 정지와 손실 정지를 설정하여 위험 통제를 진행할 수 있습니다</p>",
                tit5Tit3: "청구서 작성 방법?",
                tit5Cont3: "<p>[거래](Trade)에서 한 제품을 선택하고 [매수/매도](SELL/BUY)를 클릭하면 거래창이 뜨고, 거래창에서</p>",
                tit5Tit4: "어떻게 창고를 정리합니까?",
                tit5Cont4: "<p>창고를 정리하려면 r창고](Position)에서 플랫폼의 [창고 보유](Open)를 클릭하고 창고를 정리하고자 하는 포지션을 클릭한 다음 오른쪽에서 [창고 l(Close) 키를 클릭합니다.。</p>",
                tit5Tit5: "청구서를 수정하거나 삭제하는 방법?",
                tit5Cont5: "<p>후크를 수정하거나 삭제하려면 [창고](Position) 내에서 보기 플랫폼의 [후크](Orders)를 클릭하고 진행 중 수정하거나 삭제할 후크를 선택합니다.</p>",
                tit6Tit1: "거래 플랫폼을 사용하면 수수료가 부과되는지 여부?",
                tit6Cont1: "<p>CoinstoreClub 완전 수수료 면제를 위한 거래 플랫폼.우리는 낮은 가격의 차를 받습니다. 귀하의 특정한 거래 상황에 따라 플랫폼에 추가 비용이 발생할 수 있습니다. 예를 들어 격야 이자가 발생할 수 있습니다.。</p>",
                tit6Tit2: "입금에 무슨 비용이 있습니까??",
                tit6Cont2: "<p>저희는 고객에게 어떠한 입금 관련 비용을 받지 않지만 귀하의 지불이나 중계은행에서 수수료를 받을 수 있습니다.우리는 당신이 관련 은행에 그들이 당신에게 어떤 비용을 받는지 조회할 것을 건의합니다.。</p>",
                tit6Tit3: "출금에 무슨 비용이 있는가?",
                tit6Cont3: "<p>CoinstoreClub 최소 요구 사항보다 적은 금액을 지불하거나 그 달의 최대 무료 출금 횟수를 초과하지 않는 한 고객에게 출금 관련 비용은 청구되지 않습니다.자세한 내용은 출금 정책을 참조하십시오.。</p>",
                tit7Tit1: "내가 CoinstoreClub 계좌에 입금한 자금이 다른 용도로 사용될 수 있는지 여부?",
                tit7Cont1: "<p>아니요.소매고객의 예금은 감독관리법규에 따라 필요할 때 단독으로 신탁구좌에 보관한다.CoinstoreClub는 고객의 자금 계좌에 자금을 입금하거나 고객의 자금 계좌에서 자금을 인출하거나 지불할 때 고객 자금에 관한 규제 규정의 규칙을 엄격히 준수합니다.。</p>",
                tit7Tit2: "만약 CoinstoreClub가 도산하거나 부채가 있다면 나의 자금도 영향을 받는가?",
                tit7Cont2: "<p>소매고객의 예금은 감독관리법규에 따라 필요할 때 단독으로 신탁구좌에 보관한다.만약 CoinstoreClub가 도산하여 번호판을 강제로 취소하거나 그 어떤 지속적인 경영도 할수 없는 상황이 발생하면 고객자금은 여전히 격리될수 있으며 감독관리법률에서 고객자금조례의 감독관리에 국한된다.</p>",
                tit7Tit3: "CoinstoreClub법률 규정에 부합되는지 여부?",
                tit7Cont3: "<p>본사는 관련 회사법과 금융감독관리법률의 규정에 완전히 부합되며 관련 감독관리기구의 수권을 거쳐 엄격히 감독관리하는 금융서비스업체이다.본 회사 는 감독 관리 의 엄격한 요구 에 따라 고객 의 자금 안전 에 전면적 이고 충분한 보장 을 제공한다。</p>",
                tit7Tit4: "고객 프로필이 안전한지 여부?",
                tit7Cont4: "<p>CoinstoreClub 개인 정보 보장 조례의 규정을 엄격히 준수하고, 고객의 개인 정보는 절대 유출되지 않는다.회사는 직원들이 법규에 규정된 보안 및 보안 엄밀한 기준을 준수하도록 보장하고, 고객 데이터에 대한 보안 및 사용 안배에 깊은 관심을 기울일 것이다.자세한 내용은 본 회사의 법률 공개 문건을 참조하십시오.。</p>",
            },
            tab4Row: {
                span: "Q 검증 방법",
                p1: "CoinstoreClub는 투자자에게 간단하고 편리한 거래 경험을 제공하는 데 중점을 둔 권위 있는 기관이 규제 권한을 부여 한 금융 기술 회사입니다.",
                p2: "혁신적인 CoinstoreClub 다원화 거래 플랫폼은 여러 차례 최고의 모바일 거래 플랫폼, 가장 혁신적인 증권사 등의 영예를 안았다.",
                p3: "CoinstoreClub 플랫폼을 통해 주식, 지수, 상품, 외환 등 수백 개의 인기 품목을 포함하여 더 광범위한 국제 금융 시장에 투자, 거래 할 수 있습니다.CoinstoreClub는 친근한 모바일 및 웹 거래 플랫폼뿐만 아니라 경쟁력 있는 거래 비용, 빠른 거래 실행, 우수한 고객 서비스 및 풍부한 정보 지원을 제공하여 투자, 거래의 좋은 기회를 적시에 포착할 수 있도록 지원합니다.",
                p4: "CoinstoreClub는 업계에서 권위 있는 여러 기관의 엄격한 규제를 받고 있습니다.",
                p5: "CoinstoreClub Holding은 케이맨 제도 금융 관리국 (CIMA) 의 승인을 받고 규제를 받고 있으며 SIB 번호판 번호는 1612446입니다.케이맨 번호판에 대한 자세한 내용은 CIMA 공식 웹 사이트 www.cima.ky(",
                p6: ")를 참조하십시오.미트라데글로벌은 호주증권투자위원회(ASIC)가 승인한 금융서비스 번호판(AFSL 398528)을 보유하고 있다.호주 번호판에 대한 자세한 내용은 ASIC 공식 웹 사이트 www.asic.gov.au(",
                p7: ")를 참조하십시오.CoinstoreClub International은 모리셔스 금융 서비스 위원회 (FSC) 의 승인을 받고 GB20025791 라이센스 번호로 규제됩니다.모리셔스 번호판에 대한 자세한 내용은 FSC 공식 웹사이트 www.fscmauritius.org(",
                p8: ")를 참조하십시오.모든 CoinstoreClub 비즈니스 운영은 엄격한 규제 하에 수행되며 모든 규정 준수",
                p9: "의 배경",
                p10: "CoinstoreClub는 금융 거래 및 금융 기술 산업의 경험과 지식이 풍부한 베테랑 팀으로 구성되어 있으며 호주 멜버른에서 설립되었습니다.",
                p11: "CoinstoreClub의 이념은 거래가 더욱 간편하고 우호적으로 변한다는 것이다.우리의 전체화 전략과 비즈니스 모델은 우리가 업계 발전과 시장 수요에 대해 전면적인 심도 있는 관찰과 인식을 가지게 함으로써 더욱 목적성 있는 자원을 배치할 수 있게 하고, 끊임없이 기술을 혁신하고 효율을 최적화하여 지속적으로 고객에게 더욱 편리하고 우호적인 거래 체험을 가져다 줄 수 있게 한다",
                p12: "왜 선택해야 하는가",
                p13: "낮은 문턱의 거래 조건",
                p14: "단순하고 직관적인 거래 플랫폼",
                p15: "경쟁력 있는 거래 비용",
                p16: "업계 의 권위 있는 감독 을 받다",
                p17: "수준 높은 온라인 지원",
                p18: "마이너스 잔액 보호",
                p19: "상 및 영예",
                p20: "CoinstoreClub는 World Finance Awards의 [최고의 멀티 자산 브로커], Global BrandsMagazine의 [아시아 태평양 지역 최고의 모바일 거래 플랫폼] [세계에서 가장 빠르게 성장하는 핀테크 브로커] 등 여러 가지 영예를 얻게 되어 매우 영광스럽습니다. 이 모든 것은 팀이 줄곧 바친 노력과 노력을 긍정합니다.",
                p21: "최고의 다원 자산 브로커",
                p22: "운영 철학",
                p23: "책임",
                p24: "모든 거래는 위험과 관련되며 CoinstoreClub는 고객에게 책임 있는 거래 환경을 제공하기 위해 더 많은 조치를 취합니다.CoinstoreClub는 산업 권위, 여러 기관의 엄격한 규제를 받고 있으며 높은 수준의 보안 및 안정성 운영 표준을 따르고 있습니다.Dell의 플랫폼은 위험 관리를 포함한 다양한 책임 있는 거래 수단을 지속적으로 제공합니다.",
                p25: "간단하면서도 아름답다",
                p26: "단순함은 아름다움이고, 디테일은 탁월한 성과를 거두며, CoinstoreClub는 간편하고 사용하기 쉬우며 사용자 경험의 모든 디테일을 꾸준히 추구한다.우리는 지속적으로 플랫폼과 서비스를 최적화하여 초보자든 경험이 풍부한 투자자든 누구나 CoinstoreClub 플랫폼을 통해 거래의 좋은 기회를 쉽게 포착할 수 있기를 바랍니다.",
                p27: "투명",
                p28: "CoinstoreClub는 고객이 공정하고 효율적인 거래 환경에 충분히 혜택을 받을 수 있도록 엄격하고 투명한 자율 감독 체계를 준수하고 전면적이고 투명한 플랫폼 제품 정보, 가격 환경과 데이터를 제공하며 모든 비용을 일률적으로 명확하게 공개하여 전체 서비스 프로세스의 완전하고 진실하며 투명함을 확보합니다.",
                p29: "혁신",
                p30: "CoinstoreClub는 과학 기술의 진보, 금융 혁신, 더 광범위한 서비스야말로 성장을 추진하는 힘이며, 끊임없이 가치를 창조하고 공유할 수 있다고 확신한다.이를 위해 우리는 개방적인 사고를 장려하고 혁신을 지속적으로 추진하여 핀테크의 혁신자와 선도자가 되기 위한 것이다.",
                p31: "목표 및 비전",
                p32: "더 많은 거래를 하고 싶은 사람들에게 공간, 시간, 시장의 제한을 받지 않는 공평하고 효율적이며 투명한 거래 환경을 제공하여 업계의 금융과 과학기술 혁신을 선도한다.",
            },
        },
        language: {
            tit: "언어 선택",
        },
        market: {
            tit: "시세 설정",
            tit1: "가격 표시",
            tit2: "등락 색",
            buy_price: "매입가",
            buy_price_tip: "매입가로 K라인을 그리다",
            sell_price: "매도 가격",
            sell_price_tip: "판매가로 K라인을 그리다",
            color1: "녹색은 오르고 빨간색은 떨어지다",
            color2: "울긋불긋하다",
        },
        notice: {
            tit: "알림 설정",
            tit1: "알림 채널 열기",
            p1: "다음 알림 채널을 열거나 확인하십시오. 중요한 메시지를 놓치지 마십시오!",
            message: "문자 메시지",
            push: "푸시",
            verify: "검증",
            verified: "검증됨",
            email: "이메일",
            type1: "마케팅 클래스",
            tit2: "캠페인 알림",
            desc2: "플랫폼의 혜택 정보, 운영 활동 등 소식",
            tit3: "운영 지침",
            desc3: "풍부하고 전문적인 지식 자료",
            tit4: "정보 알림",
            desc4: "최근 경제 데이터 및 뉴스 정보",
            tit5: "지능형 변동 알림",
            desc5: "주요 품목의 가격 변동이 포함되어 있어 기회를 빠르게 포착할 수 있음",
            type2: "보고서 클래스",
            tit6: "계정 보고서",
            desc6: "계정 보고서는 한 달에 한 번씩 발송됩니다.",
            cycle: "주기",
            month: "매월",
        },
        protocol: {
            tit: "선언 및 계약",
            label1: "개인 정보 보호 정책",
            label2: "제품 공개 선언",
            label3: "고객 계약",
            label4: "위험 공개 선언",
        },
        setting: {
            tit: "설치",
            label1: "보안 설정",
            label2: "시세 설정",
            label3: "거래 설정",
            label4: "언어",
            label5: "테마 모드",
            label6: "알림 설정",
            label7: "거래 안내서",
            label8: "선언 및 계약",
            label9: "캐시 정리",
            label10: "버전 번호",
            logout: "로그인 종료",
        },
        theme: {
            tit: "테마 모드",
            theme1: "연한 모드",
            theme2: "어두운 모드",
        },
        trade: {
            tit: "거래 설정",
            onclick_close: "원클릭 창고 정리",
        }
    },
    trade: {
        fliter_type1: "자유 선택",
        fliter_type2: "시장",
        place_search: "품목 이름 / 코드 검색 입력",
        fliter_type3: "인기",
        fliter_type4: "외화",
        fliter_type5: "상품",
        fliter_type6: "주식",
        fliter_type7: "지수",
        fliter_type8: "암호화",
        tip1: "인증 완료 시 글로벌 투자 및 자금 거래 가능",
        sell: "매도",
        buy: "매입",
        add_optional: "사용자 선택 추가",
        optional_recommend: "자유 추천",
        change_batch: "한 무더기 바꾸다",
        edit_tit: "선택 편집",
        breed: "품종",
        topping: "상단",
        sort: "정렬",
        all: "모두 선택",
    },
    table: {
        label_breed: "품종",
        label_direction: "방향",
        label_price: "가격",
        label_operate: "작업",
        empty_tip1: "알림 없음",
        empty_tip2: "입금 기록이 없습니다.",
        empty_tip3: "출금 기록이 없다.",
        label_amount: "금액",
        label_type: "유형",
        label_time: "시간",
        label_name: "이름",
        label_email: "이메일",
        label_address: "거주 국가",
        label_account: "거래 계정",
        label_currency: "기본 통화",
        label_company: "현재 계좌를 개설한 회사.",
    },
    form: {
        to: "다음",
        start_time: "시작 시간",
        end_time: "종료 시간",
        week: "근 일주일",
        month: "근 한 달",
        threeMonth: "근 3개월",
        sixMonth: "근 반년",
        label_feedback: "질문 및 제안",
        place_feedback: "질문 또는 권장 사항을 입력하십시오.",
        label_img: "그림",
        label_img_tip: "질문 캡처 기능을 제공하는 옵션",
        feedback_tip: "긴급한 문제가 있으면 문의하십시오.",
        online: "온라인 고객 서비스",
        label_cardNumber: "증명서 번호",
        label_realName: "전체 이름",
        place_realName: "이름을 입력하십시오.",
        message_realName: "전체 이름을 입력하십시오.",
        label_firstName: "성씨",
        place_firstName: "성을 입력하십시오.",
        message_firstName: "성을 입력하십시오.",
        label_lastName: "이름",
        place_lastName: "중간 이름을 입력하십시오.",
        message_lastName: "이름을 입력하십시오.",
        label_birthday: "생년월일",
        place_birthday: "생년월일을 입력하십시오.",
        message_birthday: "생년월일을 입력하십시오.",
        label_idType: "증명서 유형",
        tip_cardMain: "신분증 정면 사진을 찍거나 올리다",
        tip_cardBack: "신분증 뒷면의 사진을 찍거나 올리다",
        tip_cardMain1: "운전면허 정면 사진을 찍거나 올리다",
        tip_cardBack1: "운전면허 뒤에 있는 사진 찍거나 올리기",
        tip_cardMain2: "여권 사진 촬영 또는 업로드",
        tip_cardBack2: "여권 사진 촬영 또는 업로드",
        label_password: "암호",
        place_password: "암호를 입력하십시오.",
        message_password: "암호를 입력하십시오.",
        label_crmpsd: "암호 확인",
        place_crmpsd: "암호를 다시 입력하십시오.",
        message_crmpsd: "암호를 다시 입력하십시오.",
        label_email: "이메일",
        place_email: "이메일 주소",
        message_email: "메일박스 입력",
        label_captcha: "인증 코드",
        place_captcha: "인증 코드를 입력하십시오.",
        message_captcha: "인증 코드를 입력하십시오.",
        get_captcha: "인증 코드 가져오기",
        label_phone: "전화기",
        place_phone: "전화 입력",
        message_phone: "전화 입력",
        labelAmount: "금액",
        placeAmount: "금액을 입력하세요",
        messageAmount: "금액을 입력하세요",
        labelWalletName: "지갑 이름",
        messageWalletName: "지갑 이름을 입력하십시오.",
        placeWalletName: "지갑 이름을 입력하십시오.",
        labelWalletAddress: "지갑 주소",
        messageWalletAddress: "지갑 주소를 입력하십시오.",
        placeWalletAddress: "지갑 주소를 입력하십시오.",
        labelWithdrawAccount: "현금 인출 계좌",
        placeWithdrawAccount: "현금 인출 계좌",
        messageWithdrawAccount: "현금 인출 계좌",
        placeTimeLimit: "시간 제한을 입력하십시오.",
        messageTimeLimit: "시간 제한을 입력하십시오.",
    },
    city: {
        albania: "알바니아",
        algeria: "알제리",
        argentina: "아르헨티나",
        armenia: "아르메니아",
        australia: "호주",
        pakistan: "파키스탄",
        austria: "오스트리아",
        bahrain: "바레인",
        belgium: "벨기에",
        bosnia_and_Herzegovina: "보스니아 헤르체고비나",
        brazil: "브라질",
        brunei: "브루나이",
        bulgaria: "불가리아",
        cambodia: "캄보디아",
        canada: "캐나다",
        cameroon: "카메룬",
        chile: "칠레",
        colombia: "콜롬비아",
        costa_Rica: "코스타리카",
        croatia: "크로아티아",
        cyprus: "키프로스",
        czech_Republic: "체코",
        denmark: "덴마크",
        dominican_Republic: "도미니카",
        egypt: "이집트",
        estonia: "에스토니아",
        ethiopia: "에티오피아",
        finland: "핀란드",
        france: "프랑스",
        georgia: "그루지야",
        germany: "독일",
        ghana: "가나",
        greece: "그리스",
        guyana: "가이아나",
        honduras: "온두라스",
        hong_Kong_China: "중국 홍콩",
        hungary: "헝가리",
        iceland: "아이슬란드",
        ireland: "아일랜드",
        italy: "이탈리아",
        india: "인도",
        indonesia: "인도네시아",
        israel: "이스라엘",
        iran: "이란",
        iraq: "이라크",
        japan: "일본",
        kazakstan: "카자흐스탄",
        kenya: "케냐",
        korea: "한국",
        kuwait: "쿠웨이트",
        kyrgyzstan: "키르기스스탄",
        laos: "라오스",
        Latvia: "라트비아",
        lithuania: "리투아니아",
        luxembourg: "룩셈부르크",
        macao_China: "중국 마카오",
        macedonia: "마케도니아",
        malaysia: "말레이시아",
        malta: "몰타",
        mexico: "멕시코",
        moldova: "몰도바",
        monaco: "모나코",
        mongolia: "몽골",
        montenegro: "몬테네그로",
        morocco: "모로코",
        myanmar: "미얀마",
        netherlands: "네덜란드",
        new_Zealand: "뉴질랜드",
        nepal: "네팔",
        nigeria: "나이지리아",
        norway: "노르웨이",
        oman: "오만",
        palestine: "팔레스타인",
        panama: "파나마",
        paraguay: "파라과이",
        peru: "페루",
        philippines: "필리핀",
        poland: "폴란드",
        portugal: "포르투갈",
        puerto_Rico: "푸에르토리코",
        qatar: "카타르",
        romania: "루마니아",
        russia: "러시아",
        republic_of_Trinidad_and_Tobago: "트리니다드 토바고",
        rwanda: "르완다",
        saudi_Arabia: "사우디아라비아",
        serbia: "세르비아",
        singapore: "싱가포르",
        slovakia: "슬로바키아",
        slovenia: "슬로베니아",
        south_Africa: "남아프리카",
        spain: "스페인",
        sri_Lanka: "스리랑카",
        sweden: "스웨덴",
        switzerland: "스위스",
        taiwan_China: "대만",
        tajikistan: "타지키스탄",
        tanzania: "탄자니아",
        thailand: "태국",
        turkey: "터키",
        turkmenistan: "투르크메니스탄",
        ukraine: "영국",
        united_Arab_Emirates: "미국",
        united_Kingdom: "우즈베키스탄",
        united_States: "베네수엘라",
        uzbekistan: "베트남",
        venezuela: "아프가니스탄",
        vietnam: "베트남",
        afghanistan: "아프가니스탄",
        angola: "앙골라",
        azerbaijan: "아제르바이잔",
        bangladesh: "방글라데시",
        belarus: "벨라루스",
        belize: "벨리즈",
        benin: "베냉",
        bhutan: "부탄",
        bolivia: "볼리비아",
        botswana: "보츠와나",
        british_Virgin_Islands: "영국령 버진 아일랜드",
        burkina_Faso: "부르키나파소",
        burundi: "부룬디",
        cape_Verde: "카보베르데",
        cayman_Islands: "케이맨 제도",
        central_African_Republic: "중앙아프리카",
        chad: "차드",
        comoros: "코모로",
        the_Republic_of_the_Congo: "콩고 (부)",
        democratic_Republic_of_the_Congo: "콩고 (김)",
        djibouti: "지부티",
        ecuador: "에콰도르",
        el_Salvador: "엘살바도르",
        equatorial_Guinea: "적도 기니",
        eritrea: "에리트레아",
        fiji: "피지",
        gabon: "가봉",
        gambia: "감비아",
        greenland: "그린란드",
        guatemala: "과테말라",
        guinea: "기니",
        haiti: "아이티",
        isle_of_Man: "맨 섬",
        cote_d_Ivoire: "코트디부아르",
        jamaica: "자메이카",
        jordan: "요르단",
        lebanon: "레바논",
        lesotho: "레소토",
        liberia: "라이베리아",
        libya: "리비아",
        madagascar: "마다가스카르",
        malawi: "말라위",
        maldives: "몰디브",
        mali: "말리",
        mauritania: "모리타니",
        mauritius: "모리셔스",
        mozambique: "모잠비크",
        namibia: "나미비아",
        nicaragua: "니카라과",
        republic_of_Niger: "니제르",
        north_Korea: "조선",
        reunion: "레위니옹",
        san_Marino: "산마리노",
        senegal: "세네갈",
        sierra_Leone: "시에라리온",
        somalia: "소말리아",
        sudan: "수단",
        suriname: "수리남",
        eswatini: "스와질란드",
        syria: "시리아",
        togo: "토고",
        tonga: "통가",
        tunisia: "튀니지",
        united_States_Virgin_Islands: "미국령 버진 아일랜드",
        uganda: "우간다",
        uruguay: "우루과이",
        vatican: "바티칸",
        yemen: "예멘",
        yugoslavia: "유고슬라비아",
        zambia: "잠비아",
        zimbabwe: "짐바브웨",
        china: "중국",
    }
};
