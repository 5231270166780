export default {
    common: {
        placeUsername: "Please enter the receiving user",
        userTransfer: "User transfer",
        expected: "Expected revenue",
        view: "View",
        confirm: "Confirm",
        cancel: "Cancel",
        deposit: "Deposit",
        withdraw: "Withdraw",
        noData: "There is currently no data available",
        submit: "Submit",
        modify: "Modify",
        hand: "Hand",
        confirm_verify: "Confirm Validation",
        next: "Next Step",
        complete: "Done",
        delete: "Delete",
        countryCode: "Country code",
        no_balance: "Insufficient account balance",
    },
    currency: {
		btcusdt: "Bitcoin",
		ethusdt: "Etherlands",
		ltcusdt: "Litecoin",
		xrpusdt: "Ripple (coin)",
		adausdt: "Ada currency",
		eosusdt: "Pomelo Coin",
		dotusdt: "Boca coin",
		trxusdt: "Wave field coin",
		xmrusdt: "Monroe Coin",
		dogeusdt: "Dogecoin",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "AUD USD",
		fx_sgbpusd: "GBP USD",
		fx_seurusd: "Eurodollar",
		fx_snzdusd: "New Zealand Dollar (NZD) USD",
		fx_susdcad: "USD CAD",
		fx_susdchf: "USD CHF",
		fx_susdjpy: "USD JPY",
		fx_saudcad: "AUD CAD",
		fx_saudchf: "AUD CHF",
		fx_saudjpy: "AUD JPY",
		fx_saudnzd: "AUD New Zealand Dollar (NZD)",
		fx_scadchf: "CAD Swiss Franc",
		fx_scadjpy: "Canadian yen",
		fx_schfjpy: "CHF JPY",
		fx_seuraud: "EUR AUD",
		fx_seurcad: "EUR CAD",
		fx_seurgbp: "Euro Pound",
		fx_seurjpy: "Euro Yen",
		fx_seurnzd: "Euro New Zealand dollar (NZD)",
		fx_sgbpaud: "GBP AUD",
		fx_sgbpchf: "GBP CHF",
		fx_sgbpjpy: "GBP JPY",
		fx_sgbpnzd: "GBP NZD",
		fx_scadnzd: "Canadian and New Zealand dollars",
		hf_CL: "WTI New York Crude Oil",
		hf_OIL: "Brent crude oil",
		hf_GC: "New York gold",
		hf_SI: "New York silver",
		hf_HG: "Copper (US)",
		hf_NG: "U.S. Natural Gas",
		hf_CAD: "Copper (chemistry)",
		hf_AHD: "London Aluminum",
		HX_AAPL: "Pomegranate",
		HX_BABA: "Alibaba, PRC e-commerce company",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (retailer)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Tesla",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "DAX30, Germany",
		HX_IBEX: "IBEX35, Spain",
		HX_SPX: "S&P 500 index",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Korea",
		HX_NZ50: "New Zealand 50",
		HX_PSI20: "Portugal PSI20",
		hf_ZSD: "London Zinc",
		hf_NID: "London Nickel",
		hf_PBD: "London lead",
		hf_SND: "London tin",
		hf_XAU: "London Gold (Spot Gold)",
		hf_XAG: "London Silver (Spot Silver)",
		hf_XPT: "Platinum futures",
		hf_XPD: "Palladium Futures",
		hf_CT: "US Cotton",
		hf_SM: "American soybean meal",
		hf_BO: "U.S. soybean oil",
		HX_SENSEX: "SENSEX, Mumbai, India",
		HX_KLSE: "FTSE Malaysia KLCI",
		HX_KSE100: "Karachi, Pakistan",
		HX_FCHI: "CAC40, France",
		HX_SX5E: "European Snooker 50",
		HX_ICEXI: "ICEX, Iceland",
		HX_ASE: "ASE Athens, Greece",
		HX_OMXC20: "OMX Copenhagen 20",
		HX_OSEBX: "OSEBX, Norway",
		HX_OMXSPI: "OMXSPI, Sweden",
		HX_AMD: "Chaowei Semiconductor",
		HX_DIS: "Disney (name)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (brand)",
		HX_NVDA: "NVIDIA, computer graphics card company",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Seagate Technology",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
    currenctType: {
        title: "Please select a currency",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dollar',
        currenctType15: 'Hong Kong currency',
        currenctType16: 'Taiwan currency',
        currenctType17: 'Ringgit',
        currenctType18: 'Singapore dollar',
        currenctType19: 'Yen',
        currenctType20: 'Euro',
        currenctType21: 'Dollar A',
        currenctType22: 'Rupiah',
        currenctType23: 'Pound',
        currenctType24: 'Thai baht',
        currenctType25: 'Dollar',
        currenctType26: 'VND',
        currenctType27: 'Korean won',
        currenctType28: 'MOP',
    },
    addnew3: {
        direction: "Direction",
        sell_price: "Sell price",
        number: "Quantity",
        type: "Delivery time",
        buy_price: "Buy price",
        profit: "P/L",
        sell_time: "Sell time",
    },
    addnew2: {
        address: {
            account_type: "Account Type",
            ifsc_code: "IFSC CODE",
            title: "Wallet address",
            account_number: "Account number",
            add_btn: "Add address",
            label_type: "Select type",
            place_type: "Please select type",
            label_number: "Account",
            place_number: "Please enter the account number",
            wallet_address: 'Wallet address',
            bank_name: 'BANK NAME',
            bank_address: "Bank address",
            name: "Name",
            payee_address: 'Beneficiary Address',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Branch Name",
            branch_code: "BRANCH CODE",
            bank_code: "Bank code",
            phone: "Cell-phone number",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Please enter content",
        },
    },
    addnew: {
        realFirst: "Please perform identity authentication first",
        payFirst: "Please set the transaction password first",
        password1: "Change login password",
        password2: "Change withdrawal password",
        paypassTit1: "Please set your withdrawal password",
        oldPass: "Old password",
        allView: "All read",
        confirmOrder: "confirm an order",
        name: "Name",
        fee: "Fee",
        orderCycle: "Timing",
        loan: "Loan",
        loanTit: "Assistance loans",
        loanHostory: "Loan records",
        labelProductTitle: "Product Name",
        immediateRepayment: "Immediate repayment",
        loadAmount: "Expected loan amount",
        RepaymentCycle: "Loan repayment cycle",
        day: "Day",
        dailyRate: "Daily Rate",
        repaymentMethod: "Repayment method",
        lendingCompany: "Loan company",
        loanTip: "Credit loan (please make sure the image is clear and visible)",
        loanTip1: "Upload property certificate",
        loanTip2: "Proof of income (employment relationship)",
        loanTip3: "Bank statement details",
        loanTip4: "Upload a photo of the front of your ID card",
        interest: "Interest",
        repaid: "Repaid",
        unpaid: "Unpaid",
        tab1: "Crypto",
        tab2: "Contract",
        tab3: "Option",
        available: "Available limit",
        pending: "Lock",
        equivalent: "Equivalent",
        fundTransfer: "Fund transfer",
        flashExchange: "Flash exchange",
        select_breed: "Select Currency",
        from: "From",
        to: "To",
        transferQuantity: "Transfer quantity",
        repaymentMethod1: "One repayment upon maturity"
    },
    params: {
        product_foreign: "Forex",
        product_shop: "Commodities",
        product_number: "Indices",
        product_stock: "Shares",
        product_coin: "Crypto",
        billTypes: [
            { v: 101, name: 'Recharge' },
            { v: 102, name: 'Deposit' },
            { v: 103, name: 'freeze' },
            { v: 104, name: 'thaw' },
            { v: 105, name: 'Deposit' },
            { v: 106, name: 'Deposit' },
            { v: 201, name: 'Pending' },
            { v: 202, name: 'Withdrawal' },
            { v: 203, name: 'Successful' },
            { v: 204, name: 'Withdrawal failed' },
            { v: 205, name: 'Withdrawal handling fee' },
            { v: 206, name: 'Transfer out' },
            { v: 207, name: 'Transfer in' },
            { v: 208, name: 'Coin transfer out' },
            { v: 209, name: 'Coin transfer in' },
            { v: 301, name: 'Contract handling fee' },
            { v: 302, name: 'Contract income' },
            { v: 303, name: 'Contract losses' },
            { v: 304, name: 'Contract Margin' },
            { v: 305, name: 'Contract deposit refund' },
            { v: 311, name: 'Purchase Options' },
            { v: 312, name: 'Option returns' },
            { v: 313, name: 'Option Refund' },
            { v: 314, name: 'Option handling fee' },
            { v: 315, name: 'Currency purchase freeze' },
            { v: 316, name: 'Currency purchase deduction' },
            { v: 317, name: 'Coin Purchase Return' },
            { v: 318, name: 'Purchase of Coins Received' },
            { v: 319, name: 'Purchase of Coins Received' },
            { v: 320, name: 'Currency sales freeze' },
            { v: 321, name: 'Currency sales deduction' },
            { v: 322, name: 'Coin sales return' },
            { v: 323, name: 'Coins sold to account' },
            { v: 324, name: 'Coins sold to account' },
            { v: 325, name: 'Currency handling fee' },
            { v: 401, name: 'Mining machine addition' },
            { v: 402, name: 'Mining machine return' },
            { v: 403, name: 'Mining machinery revenue' },
            { v: 404, name: 'Mining machine exit' },
            { v: 405, name: 'Mining machine exit handling fee' },
            { v: 411, name: 'Borrowing income' },
            { v: 412, name: 'Closing cost' },
            { v: 413, name: 'Successful repayment' },
            {v: 421, name: 'User transfer out'},
            {v: 422, name: 'User transfer in'},
        ],
        transType0: "Spot goods",
        transType1: "Contract",
        directionType0: "Buy",
        directionType1: "Selling",
        lockStateType0: "Running",
        lockStateType1: "Ended",
        lockStateType2: "已取消",
    },
    lockming: {
        profit: "Recent (daily earnings)",
        cycle: "financial cycle",
        limit: "Single limit",
        dividend_time: "Dividend payout time",
        every_day: "daily",
        funds: "Escrow funds",
        back: "Return on expiry",
        ransom: "Early redemption",
        label_amount: "Pledge Amount",
        place_amount: "Please enter,,,",
        available: "available",
        expect_income: "Expected income",
        title: "Locked Mining",
        tip: "Mining for income",
        label_today_income: "Estimated earnings today",
        label_total_income: "Cumulative income",
        label_order: "Orders in escrow",
        label_number: "Single amount",
        daily_return: "Daily return",
        subscribe: "subscribe",
        position: "Position",
        label_state: "Status",
        subscribe_time: "subscription_time",
        expiration_time: "expiration time",
        income: "income",
        errorTip1: "Please enter the amount",
        tip_tit: "Are you sure you want to exit early",
    },
    footer: {
        nav1: "Home",
        nav2: "Trade",
        nav3: "Positions",
        nav4: "Hub",
        kline_set1: "Market setting",
        kline_set2: "Transaction Settings",
    },
    login: {
        login: "Login",
        register: "Register",
        type1: "Phone Number",
        type2: "Email",
        login_btn: "Log In",
        register_btn: "Register Now",
        psd_login: "Password login",
        verify_login: "Verification code login",
        forget: "Forgot password?",
        no_account: "No account?",
        have_account: "Existing account",
    },
    home: {
        home: {
            nav1: "Daily Ranking",
            nav2: "Basics",
            nav3: "About Us",
            nav4: "Referral",
            most_follow: "Popular",
            news_type1: "Stratery",
            news_type2: "News",
            news_type3: "Calendar",
            news_tab1: "All",
            news_tab2: "Forex",
            news_tab3: "Shares",
            news_tab4: "Commodity",
            news_tab5: "Indices",
            news_tab6: "Data",
            news_tab7: "Event",
            news_tab8: "Vacation",
            label_previous: "Previous",
            label_predictive: "Predictive",
            label_announced: "Announced ",
        },
        kilne: {
            label_bp: "Close",
            label_sp: "Open",
            label_height: "Height",
            label_low: "Minimum ",
            tip: "Hi, I am the CoinstoreClubGPT variety assistant",
            row1_tit: "Strategy",
            row1_rinei: "Intraday",
            row1_short: "Short Term",
            row1_middle: "Middle Term",
            row1_time: "Release time",
            row1_tit2: "Trading Strategy",
            data_sources: "Data sources",
            row2_tit1: "Data",
            row2_tit2: "Info",
            row2_label_sell: "Seller",
            row2_label_buy: "Buyer",
            tip2: "For reference only, not as our company's position",
            tip3: "Update every 15 minutes",
            row2_tit3: "Price fluctuations",
            minute: "Minute",
            today: "Today",
            right_tit7: "Price variation range",
            now_price: "Current Price",
            right_low_price: "Low",
            right_height_price: "Height",
            details: "Details",
            right_label1: "Number of single transactions",
            right_label2: "Maximum leverage",
            right_label3: "Contract Size",
            right_label4: "Maximum total position quantity",
            right_label5: "Floating price difference",
            right_label6: "Overnight fee",
            hand: "Hand",
            right_sell: "Sell",
            right_buy: "Buy",
            right_collection_time: "Collection time",
            right_tip3: "Collect from the entire transaction size",
            right_label9: "Trading session",
            right_label10: "Current period",
            right_label11: "Next Period",
        },
        placeorder: {
            direction_buyPrice: "Buying rate",
            direction_sellPrice: "Selling rate",
            direction_sell: "Sell",
            direction_buy: "Buy",
            market_price: "Market order",
            check_price: "Registration",
            sell_tip: "Sell when the price meets the conditions",
            buy_tip: "Buy when the price meets the conditions",
            trade_number: "Transaction quantity",
            hand: "Hand",
            select_lever: "Selection lever",
            right_tip: "Deposit required",
            balance: "Available",
            label_stop_win_price: "Stop surplus",
            label_stop_lose_price: "Stop loss",
            expected_profit_loss: "Expected profit and loss",
            trailing_stop: "Trailing Stop",
        },
        ranking: {
            tit: "Day up and down ranking",
            p: "Capture hotspots and seize opportunities",
            renew: "Updated On",
            growth_chart: "Growth chart",
            drop_list: "Drop List",
            show_closed_market: "Displaying Off Market Brands",
            filter1: "Whole",
            filter2: "Foreign ",
            filter3: "Commodity",
            filter4: "Share certificate",
            filter5: "Index",
            filter6: "Crypto",
            td1: "Ranking",
            td2: "Trend",
            td3: "Daily increase/selling price",
        },
        remind: {
            now_sell_price: "Current selling price",
            direction_buyPrice: "Buying rate",
            direction_sellPrice: "Selling rate",
            direction_sell: "Sell",
            direction_buy: "Buy",
            equal: "Equal",
            p: "Remind me when the following prices are reached",
            btn1: "Set reminders",
            list_tit: "Reminder List",
        },
    },
    my: {
        report: {
            tit: "Account Report",
            tab1: "report",
            tab2: "Daily statement",
            tab3: "monthly statement",
            history_tit: "Transaction History Report",
            account_tit: "Account Report",
            filter: "screen",
            tip1: "Please select the appropriate date range to export your transaction history report.",
            tip2: "Custom time",
            email_send: "Send to email",
        },
        accountMsg: {
            tit: "Account information",
            label1: "Personal Information",
        },
        bill: {
            tit: "Capital flow details",
            all: "Whole",
            time: "Time",
        },
        feedback: {
            tit: "Functional recommendations",
        },
        identity: {
            tit: "Complete Profile",
            type1: "National ID",
            type2: "Driver Licence",
            type3: "Passport",
            tip: "I confirm that all information and documents provided are complete, true, and accurate",
        },
        my: {
            is: "Stop",
            no: "Unverified",
            verify: "Verified",
            id_verify: "Identity Verification",
            verify_tip: "Complete identity verification to deposit and begin trading",
            no_verify: "Verify Now",
            balance: "Available",
            profit_loss: "Profit and loss",
            marginLevel: "Margin Level",
            btn1: "Switch to a real account",
            deposit_withdraw_record: "Deposit and withdrawal records",
            capital_flow_details: "Capital flow details",
            welfare_center: "Welfare Center",
            tip1: "You have",
            tip2: "Pen reward to be claimed",
            feedback: "Functional recommendations",
            report: "Account Report",
            setting: "Settings",
        },
        news: {
            tit: "Notice",
        },
        record: {
            tit: "Deposit and withdrawal records",
            cycle: "Cycle",
        },
        share: {
            tit: "Invite friends",
            invite_success: "Successfully invited friends",
            tip1: "Cash rewards",
            tip2: "Invite friends to take the highest",
            tip3: "Invite friends to collect cash",
        },
        welfare: {
            tit: "Welfare Center",
            tab1: "Card roll",
            tab2: "Activity",
            history_record: "History",
            period_validity: "Period of validity",
        },
        wallet: {
            tit: "Wallet",
            addNew: "Add a new wallet",
        },
        withdraw: {
            tit: "Cash out",
            addNew: "Add a new wallet",
        },
    },
    order: {
        demo_account: "Simulated account",
        real_account: "Live Account",
        tip: "After opening, gold can be deposited for transactions",
        tab1: "Position",
        tab2: "Registration",
        tab3: "History",
        list_empty: "There are currently no pending orders",
        start_trade: "Start trading",
        hand_tip: "Input range",
        kline: {
            direction_sell: "Sell",
            direction_buy: "Buy",
            open_price: "Open price",
            now_price: "Current price",
            trade_account: "Trading account",
            demo_account: "Simulated account",
            order_id: "Order ID",
            open_time: "Open Time",
            stop_profit: "Stop surplus",
            stop_loss: "Stop loss",
            trailing_stop: "Trailing Stop",
            float_profit_loss: "Floating P/L",
            overnight_fee: "Overnight fee",
            margin: "Margin",
            maintainsMargin: "Maintenance Margin",
            open_value: "Opening value",
            now_value: "Current value",
            notset: "Not set",
            close_position: "Closing position",
            close_number: "Normal quantity",
            onclick_close: "One click closing position",
            btn: "Confirm closing position",
            balance: "Available",
            profit_loss: "Profit and loss",
            netValue: "Net worth",
            marginLevel: "Margin Level",
        },
        modify: {
            tit: "Modify Position Order",
            expected_profit_loss: "Expected profit and loss",
        },
    },
    setting: {
        security: {
            modify_password_tit: "Set a new password",
            verify_email_tit: "Verify email",
            verify_password_tit: "Verify your password",
            verify_password_tip: "Enter your CoinstoreClub login password to continue",
            verify_phone_tit: "Verify phone number",
            tit: "Security",
            tip: "To protect the security of your account and accept our service notifications, it is recommended to enable at least one type of authentication.",
            label1: "Change password",
            label2: "Unsubscribe",
            label3: "Dual verification",
            label4: "Equipment management",
            tip2: "New device login requires verification",
        },
        tradenews: {
            tit: "Transaction Guide",
            tab1: "Quick get start",
            tab2: "Advanced learning",
            tab3: "Help",
            tab4: "About CoinstoreClub",
            viewMore: "View More",

            details: {
                tit: "Introduction to Foreign Exchange",
                tip1: "Estimated learning duration",
                tip2: "Minute",
            },
            tab1Row: {
                tit: "This is a classroom for quickly learning to trade with CoinstoreClub",
                tit1: "How is trading done on CoinstoreClub?",
                tit2: "How to open a real account？",
                tit3: "How to open a position for trading？",
                tit4: "How to set stop profit and stop loss？",
                tit5: "How to close a position？",
                p1: "You can trade the subject matter by buying or selling it. Opening a position in CoinstoreClub means that you are interested in the",
                p2: "Trading based on price fluctuations of the target product",
                p3: "For example, if you expect the price of gold to rise, buy the product; On the contrary, if you predict that the price of gold will decrease, then sell the product. If the future meets expectations, you can",
                p4: "Profiting from it",
                p5: "In addition, CoinstoreClub provides ''leveraged trading'. You can use leverage to trade more targets with the same amount of funds.",
                p6: "Leveraging can amplify profits and also amplify losses",
                p7: "This is also known as' margin trading '. When you open a position, your CoinstoreClub account will deduct a certain balance as",
                p8: "INITIAL MARGIN",
                p9: "Select products and buying and selling directions",
                p10: "Transaction",
                p11: "Click 'Buy/Sell' to complete the order placement",
                p12: "Setting 'stop profit' or 'stop loss' can help you",
                p13: "Timely lock in profits and limit losses",
                p14: "You can click on the successful opening page",
                p15: "Stop profit/stop loss",
                p16: "Set it up, or you can click on the 'Modify' button for the non balanced order to stop profit and loss",
                p17: "Set or cancel",
                p18: "You can view profits and losses in 'closed positions'",
            },
            tab3Row: {
                h1: "Welcome to CoinstoreClub",
                h2: "Help",
                tit0: "All issues",
                tit1: "Simulated account",
                tit2: "Opening an account",
                tit3: "Cash in and out",
                tit4: "Platform Settings",
                tit5: "Transaction Operations",
                tit6: "Fees and Charges",
                tit7: "Financial Security and Regulations",
                tit1Tit1: "Does CoinstoreClub provide simulated accounts",
                tit1Cont1: "<p>CoinstoreClub provides simulated accounts for customers to operate, allowing you to use our online platform and mobile applications for transactions and familiarize yourself with platform operations without any risks</p>",
                tit1Tit2: "How to create a simulated account?",
                tit1Cont2: "<p>You just need to click here to fill in your email address or phone number, and set your personal password to complete the registration and obtain a simulated account </p>",
                tit1Tit3: "What is the use of simulated accounts?",
                tit1Cont3: "<p>Simulated accounts are mostly the same as real accounts in terms of transaction interface, data, and operations. There is a virtual fund of $50000 in the simulated account, with the aim of familiarizing customers with the platform's various functions through simulated operations without any financial risk</p>",
                tit1Tit4: "How to inject funds into a simulated account?",
                tit1Cont4: "<p>You do not need to inject funds into the simulation account. Once you successfully register the simulation account, there will be a total of 50000 USD or AUD in the account</p><p>When the net value in the simulated account is less than $200 USD or AUD, the system will automatically replenish your account funds to $50000 USD or AUD</p>",
                tit1Tit5: "Does the simulated account have a usage period",
                tit1Cont5: "<p>The validity period of the simulated account is 90 days. If you do not open a real account after 90 days, the simulated account will be frozen and cannot be traded, and will be unfrozen when you open a real account。</p><p>If you open a real account within 90 days, the simulated account will be valid for a long time. Even after opening a real account, you can continue to operate on a simulated account。</p>",
                tit2Tit1: "How many account types does CoinstoreClub offer?",
                tit2Cont1: "<p>We only provide standard and professional trading accounts for individuals, and do not support opening company or joint accounts</p>",
                tit2Tit2: "What currency can I open a trading account in?",
                tit2Cont3: "<p>When you open an account, the system will automatically determine your settlement currency based on your region and it will not be modifiable</p>",
                tit2Tit4: "How to open a real account?",
                tit2Cont4: "<p>Please follow the steps below to open a real account</p><p>1. Enter the CoinstoreClub registration page and follow the prompts to register an email phone number, set a login password, and automatically create it after submitting confirmation</p><p></p><p></p>",
                tit3Tit1: "How can I make a deposit?",
                tit3Cont1: "<p>CoinstoreClub CoinstoreClub offers different deposit methods for customers to choose from, including but may not be limited to the following:</p><p>1.Visa/Mastercard - Usually received immediately</p><p>2.Online banking payment is generally made within 1 working day in advance</p><p>3.Electronic wallets (such as Skrill, Momo, Zalo. Touch'n GO, Boost, etc.) - typically instant receipt</p><p>4. ATM card - usually instant receipt</p><p>5.QR mobile online banking - usually instant receipt</p>",
                tit3Tit2: "Can I use a bank account/card under a third-party name to deposit or withdraw funds",
                tit3Cont2: "<p>CoinstoreClub does not accept third-party deposits. Please use your personal bank account/card under your own name to deposit or withdraw funds. If you use someone else's name or company bank account/card to deposit money，The funds will be returned</p>",
                tit3Tit3: "How to apply for withdrawal from CoinstoreClub account?",
                tit3Cont3: "<p>After logging into the website platform, you can click on 'Cash Out', select a bank account or Visa/Mastercard, fill in the cash out amount, and then press' Submit '. After confirming that the information is correct, CoinstoreClub will process your application within 1-2 working days</p>",
                tit4Tit1: "How many times does CoinstoreClub offer leveraged trading?",
                tit4Cont1: "<p>The leverage ratio provided by the CoinstoreClub platform is set according to regulatory authorities' regulations, and the maximum leverage for different products also varies. Please log in to the trading platform for details.。</p>",
                tit4Tit2: "How to change my password?",
                tit4Cont2: "<p>After logging into the platform, you can choose [More] - [Security Settings] - T Change Password] to reset the new password</p>",
                tit4Tit3: "How to log in to the CoinstoreClub trading platform?",
                tit4Cont3: "<p>App:Go to 'My' and click on 'Login Registration' in the upper left corner to enter the login page。</p>Web: Click on 'Login' in the upper right corner of CoinstoreClub's official website to enter the login page。<p></p><p></p><p></p><p></p>",
                tit4Tit4: "What are the methods for platform reminders?",
                tit4Cont4: "<p>CoinstoreClub alerts through email, SMS, and platform push.</p>",
                tit5Tit1: "What price difference does CoinstoreClub offer?",
                tit5Cont1: "<p>We charge a low price difference and do not charge any commission. The price difference is floating, and the actual price difference charged will depend on the variety you are trading, and the specific information will be displayed on the trading platform in real-time.</p>",
                tit5Tit2: "How to establish a market price list?",
                tit5Cont2: "<p>Clicking on 'Trade' will display all the products that the platform can offer for trading. You can enter the variety code or name in the input box on the right to search, select a product, and click 'Buy/Sell' SEL/BUY 'to pop up a trading window. In the trading window, you can see the current price and expected required margin. You can manually adjust the number of open positions and set up stop profit and stop loss for risk control</p>",
                tit5Tit3: "How to establish a registration form?",
                tit5Cont3: "<p>Selecting a product in [Trade] and clicking on [Sell/BUY] will pop up a trading window</p>",
                tit5Tit4: "How to close a position?",
                tit5Cont4: "<p>To close a position, click on ''Open'' on the platform in 'Position',' then select the position you want to close, and then click on the ''Close' button on the right side</p>",
                tit5Tit5: "How to modify or delete a pending order?",
                tit5Cont5: "<p>To modify or delete orders, click on ''Orders'' on the viewing platform in the ''Position'' section, and then select the orders you want to modify or delete in progress</p>",
                tit6Tit1: "Will there be a handling fee charged for using the trading platform?",
                tit6Cont1: "<p>CoinstoreClub is a completely commission free trading platform. We charge a low price difference, and depending on your specific transaction situation, the platform may incur additional fees, such as overnight interest</p>",
                tit6Tit2: "Is there any fee for the deposit?",
                tit6Cont2: "<p>We will not charge any deposit related fees to customers, but your payment or intermediary bank may charge handling fees. We suggest that you inquire with the relevant banks whether they have charged you any fees.</p>",
                tit6Tit3: "Is there any fee for the withdrawal?",
                tit6Cont3: "<p>CoinstoreClub will not charge customers any fees related to the withdrawal of funds unless the amount you withdraw is below the minimum requirement or exceeds the maximum number of free withdrawals for the month. For more detailed information, please refer to our withdrawal policy.</p>",
                tit7Tit1: "Will the funds I deposit in my CoinstoreClub account be used for other purposes?",
                tit7Cont1: "<p>No. Retail customer deposits are separately deposited in trust accounts when necessary in accordance with regulatory regulations. When depositing funds into customer accounts or withdrawing funds from customer accounts or making payments, CoinstoreClub strictly complies with regulatory regulations regarding customer payments.</p>",
                tit7Tit2: "If CoinstoreClub goes bankrupt or is in debt, will my funds also be affected?",
                tit7Cont2: "<p>Retail customer deposits are separately deposited in trust accounts when necessary in accordance with regulatory regulations. If CoinstoreClub goes bankrupt, has its license revoked, or is unable to continue operating, customer funds can still be quarantined and subject to the supervision of customer funds regulations in regulatory laws</p>",
                tit7Tit3: "Does CoinstoreClub comply with legal regulations?",
                tit7Cont3: "<p>Our company fully complies with relevant company laws and financial regulatory laws and regulations, and is a financial service provider authorized and strictly regulated by relevant regulatory agencies. Our company provides comprehensive and sufficient protection for customers' financial security in accordance with strict regulatory requirements.</p>",
                tit7Tit4: "Is the customer's personal information secure?",
                tit7Cont4: "<p>CoinstoreClub strictly complies with the provisions of the Personal Data Protection Regulations, and customers' personal data will never be disclosed. The company will ensure that employees comply with the strict standards of security and confidentiality stipulated by laws and regulations, and pay high attention to arrangements for the confidentiality and use of customer data. For details, please refer to our company's legal disclosure documents.</p>",
            },
            tab4Row: {
                span: "Q verification method",
                p1: "CoinstoreClub is a financial technology company authorized and regulated by authoritative institutions, focusing on providing investors with a simple and convenient trading experience.",
                p2: "The innovative CoinstoreClub diversified trading platform has repeatedly won awards such as the best mobile trading platform and the most innovative securities firm.",
                p3: "Through the CoinstoreClub platform, you can invest and trade in a wider range of international financial markets, covering hundreds of popular varieties such as stocks, indices, commodities, foreign exchange, etc. Not only limited to user-friendly mobile and web trading platforms, CoinstoreClub also provides you with competitive transaction costs, fast transaction execution, excellent customer service, and rich information support, helping you seize investment and trading opportunities in a timely manner.",
                p4: "CoinstoreClub is strictly regulated by industry authorities and multiple institutions.",
                p5: "CoinstoreClub Holding is authorized and regulated by the Cayman Islands Monetary Authority (CIMA) with a SIB license number of 1612446. For more details on the Cayman license plate, please visit the official website of CIMA at www.cima.ky（",
                p6: ") Conduct a query. CoinstoreClubGlobal holds a Financial Services License (AFSL 398528) authorized by the Australian Securities and Investment Commission (ASIC). For more details on the Australian license plate, please visit the ASIC official website at www.asic.gov.au (",
                p7: ") Conduct a query. CoinstoreClub International is authorized and regulated by the Mauritius Financial Services Commission (FSC) with a license number of GB20025791. For more information on the Mauritius license plate, please visit the FSC official website at www.fscmauricius.org(",
                p8: ")Conduct a query. All business operations of CoinstoreClub are conducted under strict supervision and comply with all regulations",
                p9: "Background",
                p10: "CoinstoreClub is a senior team with rich experience and knowledge in the financial trading and fintech industries, founded in Melbourne, Australia",
                p11: "CoinstoreClub's philosophy is to make transactions easier and more friendly. Our holistic strategy and business model enable us to have a comprehensive and in-depth understanding of industry development and market demand, enabling us to allocate resources more targeted, continuously innovate technology, and optimize efficiency, continuously bringing customers more convenient and friendly trading experiences",
                p12: "Why choose",
                p13: "Low threshold trading conditions",
                p14: "Simple and intuitive trading platform",
                p15: "Competitive transaction costs",
                p16: "Supervised by industry authorities",
                p17: "High level online support",
                p18: "Negative balance protection",
                p19: "Awards and Honors",
                p20: "CoinstoreClub is honored to receive multiple awards, including World Finance Awards' Best Multi Asset Broker, Global BrandsMagazine's Best Mobile Trading Platform in the Asia Pacific Region, and Global Fastest Growing Financial Technology Broker, all of which confirm the team's continuous efforts and dedication.",
                p21: "Best Multi Asset Broker",
                p22: "Our operational philosophy",
                p23: "Responsibility",
                p24: "All transactions involve risks, and CoinstoreClub is taking more measures to provide customers with a responsible trading environment. CoinstoreClub is subject to industry authority, strict supervision from multiple institutions, and adheres to high-level safety and stability operating standards. Our platform continues to provide multiple responsible trading tools, including risk management",
                p25: "Simple yet beautiful",
                p26: "Simplicity is beauty, and details achieve excellence. CoinstoreClub strives for every detail of simplicity, ease of use, and user experience. We continue to optimize our platform and services, hoping that everyone, whether novice or experienced investors, can easily seize trading opportunities through the CoinstoreClub platform.",
                p27: "Transparent",
                p28: "In order to fully benefit our customers from a fair and efficient trading environment, CoinstoreClub follows a rigorous and transparent self-regulation system, providing comprehensive and transparent platform product information, pricing environment, and data. All fees are clear and transparent, ensuring the complete sincerity and transparency of the entire service process.",
                p29: "Innovate",
                p30: "CoinstoreClub firmly believes that technological progress, financial innovation, and broader services are the driving forces for growth, in order to continuously create and share value. For this reason, we encourage open thinking, continuously promote innovation, and aim to become innovators and leaders in financial technology.",
                p31: "Goals and Vision",
                p32: "To provide a fair, efficient, and transparent trading environment that is not limited by space, time, or market for more people who want to conduct transactions, leading the financial and technological innovation of the industry.",
            },
        },
        language: {
            tit: "Choose Language",
        },
        market: {
            tit: "Market setting",
            tit1: "Price display",
            tit2: "Color of ups and downs",
            buy_price: "Buying rate",
            buy_price_tip: "Draw a K-line at the buying price",
            sell_price: "Selling rate",
            sell_price_tip: "Draw K-line at selling price",
            color1: "Green rise and red fall",
            color2: "Red rise and green fall",
        },
        notice: {
            tit: "Notifications settings",
            tit1: "Open notification channels",
            p1: "Please open/verify the following notification channels, important messages are not missed!",
            message: "Text message",
            push: "Push",
            verify: "Test and verify",
            verified: "Verified",
            email: "E-mail",
            type1: "MARKETING",
            tit2: "Marketing Event Notification",
            desc2: "Platform's promotional information, operational activities, and other messages",
            tit3: "Operating instructions",
            desc3: "Rich and professional knowledge materials",
            tit4: "Information reminder",
            desc4: "Recent financial data and news information",
            tit5: "Intelligent fluctuation reminder",
            desc5: "Including price fluctuations of important varieties, helping you quickly seize opportunities",
            type2: "Report Class",
            tit6: "Account Report",
            desc6: "Your account report will be sent monthly in the following way",
            cycle: "Cycle",
            month: "Monthly",
        },
        protocol: {
            tit: "Declaration and Agreement",
            label1: "Privacy Policy",
            label2: "Product Disclosure Statement",
            label3: "Customer Agreement",
            label4: "Risk Disclosure Statement",
        },
        setting: {
            tit: "Settings",
            label1: "Security setting",
            label2: "Market setting",
            label3: "Transaction Settings",
            label4: "Language",
            label5: "Theme mode",
            label6: "notifications settings",
            label7: "Transaction Guide",
            label8: "Declaration and Agreement",
            label9: "Clean cache",
            label10: "Version",
            logout: "Log out of login",
        },
        theme: {
            tit: "Theme mode",
            theme1: "Light",
            theme2: "Dark",
        },
        trade: {
            tit: "Transaction Settings",
            onclick_close: "One click closing position",
        }
    },
    trade: {
        fliter_type1: "Optional",
        fliter_type2: "Market",
        place_search: "Enter variety name/code search",
        fliter_type3: "Popular",
        fliter_type4: "Foreign ",
        fliter_type5: "Commodity",
        fliter_type6: "Share ",
        fliter_type7: "Index",
        fliter_type8: "Crypto",
        tip1: "Complete identity verification to deposit and begin trading",
        sell: "Sell",
        buy: "Buy",
        add_optional: "Add Self Selection",
        optional_recommend: "Self selection recommendation",
        change_batch: "Change batch",
        edit_tit: "Edit Selection",
        breed: "Breed",
        topping: "Topping",
        sort: "Sort",
        all: "Select All",
    },
    table: {
        label_breed: "Breed",
        label_direction: "Direction",
        label_price: "Price",
        label_operate: "Operate",
        empty_tip1: "No reminder at the moment",
        empty_tip2: "No deposit records",
        empty_tip3: "No cash disbursement record",
        label_amount: "Amount",
        label_type: "Types ",
        label_time: "Time",
        label_name: "Name",
        label_email: "Email",
        label_address: "Country",
        label_account: "Trading Account Number",
        label_currency: "Base Currency",
        label_company: "Current Service Entity",
    },
    form: {
        to: "To",
        start_time: "Start time",
        end_time: "End time",
        week: "Last week",
        month: "Last month",
        threeMonth: "Last three month",
        sixMonth: "Last six month",
        label_feedback: "Problems and suggestions",
        place_feedback: "Please enter your question or suggestion",
        label_img: "Picture",
        label_img_tip: "Optional, provide screenshots of the problem",
        feedback_tip: "If you have urgent questions, please contact",
        online: "Online Service",
        label_cardNumber: "ID Number",
        label_realName: "First Name",
        place_realName: "Please Enter Your First Name",
        message_realName: "Please Enter Your First Name",
        label_firstName: "Last Name",
        place_firstName: "Please Enter Your Last Name",
        message_firstName: "Please Enter Last Name",
        label_lastName: "Middle Name",
        place_lastName: "Optional",
        message_lastName: "Please Enter A Name",
        label_birthday: "Date of Birth",
        place_birthday: "DD/MM/YYYY",
        message_birthday: "Please Enter The Date Of Birth",
        label_idType: "Verification Type",
        tip_cardMain: "Take or Upload a photo of the FRONT of your identity card",
        tip_cardBack: "Take or Upload a photo of the BEHIND of your identity card",
        tip_cardMain1: "Take or Upload a photo of the FRONT of your driver's license",
        tip_cardBack1: "Take or Upload a photo of the BEHIND of your driver's license",
        tip_cardMain2: "Take or Upload a photo of your passport",
        tip_cardBack2: "Take or Upload a photo of your passport",
        label_password: "Password",
        place_password: "Please enter the password",
        message_password: "Please enter the password",
        label_crmpsd: "Confirm Password",
        place_crmpsd: "Please enter the password again",
        message_crmpsd: "Please enter the password again",
        label_email: "E-mail",
        place_email: "Email Address",
        message_email: "Please enter your email address",
        label_captcha: " Verification code",
        place_captcha: "Please enter the verification code",
        message_captcha: "Please enter the verification code",
        get_captcha: "Verification Code",
        label_phone: "Phone",
        place_phone: "Please enter a phone number",
        message_phone: "Please enter a phone number",
        labelAmount: "Amount",
        placeAmount: "Please enter  amount",
        messageAmount: "Please enter  amount",
        labelWalletName: "Wallet name",
        messageWalletName: "Please enter the wallet name",
        placeWalletName: "Please enter the wallet name",
        labelWalletAddress: "Wallet address",
        messageWalletAddress: "Please enter the wallet address",
        placeWalletAddress: "Please enter the wallet address",
        labelWithdrawAccount: "Withdrawal account",
        placeWithdrawAccount: "Withdrawal account",
        messageWithdrawAccount: "Withdrawal account",
        placeTimeLimit: "Please enter timeLimit",
        messageTimeLimit: "Please enter timeLimit",
    },
    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrain",
        belgium: "Belgium",
        bosnia_and_Herzegovina: "Bosnia and Herzegovina",
        brazil: "Brazil",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Cambodia",
        canada: "Canada",
        cameroon: "Cameroon",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia",
        cyprus: "Cyprus",
        czech_Republic: "Czech Republic",
        denmark: "Denmark",
        dominican_Republic: "Dominican Republic",
        egypt: "Egypt",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Finland",
        france: "France",
        georgia: "Georgia",
        germany: "Germany",
        ghana: "Ghana",
        greece: "Greece",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungary",
        iceland: "Iceland",
        ireland: "Ireland",
        italy: "Italy",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Japan",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kyrgyzstan",
        laos: "Laos",
        latvia: "Latvia",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        macao_China: "Macao_China",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexico",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Montenegro",
        morocco: "Morocco",
        myanmar: "Myanmar",
        netherlands: "Netherlands",
        new_Zealand: " New_Zealand",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norse",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippines",
        poland: "Poland",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romania",
        russia: "Russia",
        republic_of_Trinidad_and_Tobago: "Republic of Trinidad and Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Saudi Arabia",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "South Africa",
        spain: "Spain",
        sri_Lanka: "Sri Lanka",
        sweden: "Sweden",
        switzerland: "Switzerland",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tajikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "United Arab Emirates",
        united_Kingdom: "United Kingdom",
        united_States: " United_States",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "British Virgin Islands",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Cayman Islands",
        central_African_Republic: "Central African Republic",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: " The_Republic_of_the_Congo",
        democratic_Republic_of_the_Congo: "Democratic Republic of the Congo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Equatorial Guinea",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Isle_of_Man",
        cote_d_Ivoire: "Cote_d_Ivoir",
        jamaica: "Jamaica",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libya",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Republic_of_Niger",
        north_Korea: " North_Korea",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "United States Virgin Islands",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
    }
};
